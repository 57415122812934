import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"

const rel1 = "\vtext {drift(%) = 1.25}";

const rel2 = "b x h = 914x457mm";
const rel3 = "L = 1219 mm";
const rel4 = "f_{c}' = 15.4 \pm 34.7 MPa";
const rel5 = "f_{y} = 420 MPa";
const rel6 = "f_{yh} = 280 MPa";
const rel7 = "\vrho_{l}(%) = 1.88";
const rel8 = "\vrho_{t}(%) = 2.1, 3.4";
const rel9 = "P/(f_{c}' A_{g}) = 0.0";
const rel10 = "M/(VH) = 1.33, 2.67";
const rel11 = "\vtext {Full steel jacket, 6.35mm thick}";

const rel12 = "\vtext {drift(%) = 4.5}";

const rel13 = "\vtext {drift(%) = 1.0}";
const rel14 = "b x h = 305 x 305mm";
const rel15 = "L = 920 mm";
const rel16 = "f_{c}' = 26.4 MPa";
const rel17 = "f_{y} = 462 MPa";
const rel18 = "f_{yh} = 414 MPa";
const rel19 = "\vrho_{l}(%) = 2.4";
const rel20 = "\vrho_{t}(%) = 0.093";
const rel21 = "P/(f_{c}' A_{g}) = 0.12";
const rel22 = "M/(VH) = 3.0";
const rel23 = "\vtext {Concrete jacket (27.6 MPa), 63.5mm thick, } \vtext {4 Φ9 long. bars and Φ6/63.5 mm ties}";

const rel24 = "\vtext {drift(%) = 2.5}";
const rel25 = "\vtext {drift(%)} = 1.03 \pm 0.17, \vtext {CFRP jacket}";
const rel26 = "\vtext {drift(%)} = 1.09 \pm 0.1, \vtext {concrete jacket}";
const rel27 = "b x h = 250 x 250mm, 250 x 500mm";
const rel28 = "L = 1600 mm";
const rel29 = "f_{c}' = 18 - 30 MPa";
const rel30 = "f_{y} = 313, 514 MPa";
const rel31 = "f_{yh} = 425 MPa";
const rel32 = "\vrho_{l}(%) = 0.99, 0.81";
const rel33 = "\vrho_{t}(%) = 0.32";
const rel34 = "P/(f_{c}' A_{g}) = 0.12";
const rel35 = "M/(VH) = 3.0";
const rel36 = "\vtext {CFRP sheets 0.13 mm thick, } E_{f}=230 GPA, f_{uf}=3450 MPa. \vtext {Concrete jacket (27.6 MPa), 75 mm thick, 4 Φ20 }(f_{y}=487 MPa) \vtext {or 4Φ18 }(f_{y}=514 MPa) \vtext {long. bars and Φ10/100 mm ties }(f_{yh}=599 MPa)}";
const rel37 = "\vtext {drift(%)} = 5.5 \pm 0.93, \vtext {CFRP jacket}";
const rel38 = "\vtext {drift(%)} = 5.1 \pm 0.65, \vtext {concrete jacket}";

const rel39 = "\vtext {drift(%)} = 1.13 \pm 0.15, \vtext {CFRP jacket}";
const rel40 = "\vtext {drift(%)} = 1.11 \pm 0.12, \vtext {concrete jacket}";
const rel41 = "b x h = 250 x 250mm";
const rel42 = "L = 1600 mm";
const rel43 = "f_{c}' = 18 - 30 MPa";
const rel44 = "f_{y} = 313, 514 MPa";
const rel45 = "f_{yh} = 425 MPa";
const rel46 = "\vrho_{l}(%) = 0.99, 0.81";
const rel47 = "\vrho_{t}(%) = 0.32";
const rel48 = "P/(f_{c}' A_{g}) = 0.08 \pm 0.45";
const rel49 = "M/(VH) = 3.0";
const rel50 = "\vtext {CFRP sheets 0.13 mm thick, } E_{f}=230 GPA, f_{uf}=3450 MPa. \vtext {Concrete jacket (27.6 MPa), 75 mm thick, 4 Φ20 }(f_{y}=487 MPa) \vtext {or 4Φ18 }(f_{y}=514 MPa) \vtext {long. bars and Φ10/100 mm ties }(f_{yh}=599 MPa)}";
const rel51 = "\vtext {drift(%)} = 5.73 \pm 1.46, \vtext {CFRP jacket}";
const rel52 = "\vtext {drift(%)} = 5.21 \pm 0.38, \vtext {concrete jacket}";

const rel53 = "\vtext {drift(%)} = 0.5";
const rel54 = "b x h = 254 x 381mm";
const rel55 = "L = 2032 mm";
const rel56 = "f_{c}' = 31 MPa";
const rel57 = "f_{y} = 331 MPa";
const rel58 = "f_{yh} = 372 MPa";
const rel59 = "\vrho_{l}(%) = 1.2";
const rel60 = "\vrho_{t}(%) = 1.31";
const rel61 = "P/(f_{c}' A_{g}) = 0.07";
const rel62 = "M/(VH) = 8.0";
const rel63 = "\vtext {CFRP sheets 1.27 mm thick, } E_{f}=69 GPA, f_{uf}=965 MPa. \vtext {Steel jacket (248 MPa), 4.7mm thick}";

const rel64 = "\vtext {drift(%)} = 2.02, \vtext {steel jacket}";
const rel65 = "\vtext {drift(%)} = 1.48, \vtext {CFRP jacket}";
const rel66 = "\vtext {drift(%)} = 4.0, \vtext {steel jacket}";
const rel67 = "\vtext {drift(%)} = 4.26, \vtext {CFRP jacket}";

const rel68 = "\vtext {drift(%)} = 4.12 \pm 0.53";
const rel69 = "\vtext {drift(%)} = 2.74 \pm 0.72";
const rel70 = "b x h = 610 x 457mm";
const rel71 = "L = 2438, 3429 mm";
const rel72 = "f_{c}' = 41 MPa";
const rel73 = "f_{y} = 299 MPa";
const rel74 = "f_{yh} = 299 MPa";
const rel75 = "\vrho_{l}(%) = 2.03";
const rel76 = "\vrho_{s}(%) = 0.08";
const rel77 = "P/(f_{c}' A_{g}) = 0.1";
const rel78 = "M/(VH) = 2.0, 2.8";
const rel79 = "\vtext {GFRP jacket 1.27 mm thick, GFRP jacket 7.62-22.8mm thick,} f_{uf}=424-937 MPa. \vtext {CFRP jacket 1.0-5.33mm thick,} f_{y}=1245-4443 MPa";


const rel80 = "\vtext {drift(%) = 1.2, steel jacket}";
const rel81 = "\vtext {drift(%) = 1.12, CFRP wraps}";
const rel82 = "\vtext {drift(%) = 1.4, concrete jacket}";

const rel83 = "b x h = 300 x 300mm";
const rel84 = "L = 1660 mm";
const rel85 = "f_{c}' = 15.7 - 19.1 MPa";
const rel86 = "f_{y} = 375 MPa";
const rel87 = "f_{yh} = 310 MPa";
const rel88 = "\vrho_{l}(%) = 2.03, 5.28";
const rel89 = "\vrho_{t}(%) = 0.35 \pm 3.48";
const rel90 = "P/(f_{c}' A_{g}) = 0.30";
const rel91 = "M/(VH) = 3.3";
const rel92 = "\vtext {GFRP sheets 0.3 mm thick, } \vepsilon_{rup} = 0.015, f_{uf} = \vtext {3450 MPa, 2 layers. Steel jacketing, 3mm plate thickness. Concrete jacketing, 70mm thickness. AMF concrete jacketing, 50mm thickness}";

const rel93 = "\vtext {drift(%) = 4.13, steel jacket}";
const rel94 = "\vtext {drift(%) = 3.6, CFRP wraps}";
const rel95 = "\vtext {drift(%) = 2.7, concrete jacket}";
const rel96 = "\vtext {drift(%) = 3.86, AMF concrete jacket}";

const rel97 = "\mu_{d} = 5.1 \pm 1.04, \vtext {CFRP jacket}";
const rel98 = "\mu_{d} = 4.7 \pm 0.51, \vtext {concrete jacket}";
const rel99 = "b x h = 250 x 250mm, 250 x 500mm";
const rel100 = "L = 1600 mm";
const rel101 = "f_{c}' = 18 - 30 MPa";
const rel102 = "f_{y} = 313, 514 MPa";
const rel103 = "f_{yh} = 425 MPa";
const rel104 = "\vrho_{l}(%) = 0.99, 0.81";
const rel105 = "\vrho_{t}(%) = 0.32";
const rel106 = "P/(f_{c}' A_{g}) = 0.12";
const rel107 = "M/(VH) = 3.0";
const rel108 = "\vtext {CFRP sheets 0.13 mm thick, } E_{f}=230 GPA, f_{uf}=3450 MPa. \vtext {Concrete jacket (27.6 MPa), 75 mm thick, 4 Φ20 }(f_{y}=487 MPa) \vtext {or 4Φ18 }(f_{y}=514 MPa) \vtext {long. bars and Φ10/100 mm ties }(f_{yh}=599 MPa)}";

const rel109 = "\mu_{d} = 5.04 \pm 1.02, \vtext {CFRP jacket}";
const rel110 = "\mu_{d} = 4.71 \pm 0.49, \vtext {concrete jacket}";
const rel111 = "b x h = 250 x 250mm, 250 x 500mm";
const rel112 = "L = 1600 mm";
const rel113 = "f_{c}' = 18 - 30 MPa";
const rel114 = "f_{y} = 313, 514 MPa";
const rel115 = "f_{yh} = 425 MPa";
const rel116 = "\vrho_{l}(%) = 0.99, 0.81";
const rel117 = "\vrho_{t}(%) = 0.32";
const rel118 = "P/(f_{c}' A_{g}) = 0.12";
const rel119 = "M/(VH) = 3.0";
const rel120 = "\vtext {CFRP sheets 0.13 mm thick, } E_{f}=230 GPA, f_{uf}=3450 MPa. \vtext {Concrete jacket (27.6 MPa), 75 mm thick, 4 Φ20 }(f_{y}=487 MPa) \vtext {or 4Φ18 }(f_{y}=514 MPa) \vtext {long. bars and Φ10/100 mm ties }(f_{yh}=599 MPa)}";

const rel121 = "\mu_{d} = 0.5";
const rel122 = "b x h = 254 x 381mm";
const rel123 = "L = 2032 mm";
const rel124 = "f_{c}' = 31 MPa";
const rel125 = "f_{y} = 331 MPa";
const rel126 = "f_{yh} = 372 MPa";
const rel127 = "\vrho_{l}(%) = 1.2";
const rel128 = "\vrho_{t}(%) = 1.31";
const rel129 = "P/(f_{c}' A_{g}) = 0.07";
const rel130 = "M/(VH) = 8.0";
const rel131 = "\vtext {CFRP sheets 1.27 mm thick, } E_{f}=69 GPA, f_{uf}=965 MPa. \vtext {Steel jacket (248 MPa), 4.7mm thick}";

const rel132 = "\mu_{d} = 3.59, \vtext {steel jacket}";
const rel133 = "\mu_{d} = 3.59, \vtext {CFRP jacket}";
const rel134 = "\mu_{d} = 7.1, \vtext {steel jacket}";
const rel135 = "\mu_{d} = 7.75, \vtext {CFRP jacket}";

const rel136 = "\mu_{d} = 6.3 \pm 0.72";
const rel137 = "\mu_{d} = 1.83 \pm 0.63";
const rel138 = "b x h = 610 x 457mm";
const rel139 = "L = 2438, 3429 mm";
const rel140 = "f_{c}' = 41 MPa";
const rel141 = "f_{y} = 299 MPa";
const rel142 = "f_{yh} = 299 MPa";
const rel143 = "\vrho_{l}(%) = 2.03";
const rel144 = "\vrho_{s}(%) = 0.08";
const rel145 = "P/(f_{c}' A_{g}) = 0.1";
const rel146 = "M/(VH) = 2.0, 2.8";
const rel147 = "\vtext {GFRP jacket 1.27 mm thick, GFRP jacket 7.62-22.8mm thick,} f_{uf}=424-937 MPa. \vtext {CFRP jacket 1.0-5.33mm thick,} f_{y}=1245-4443 MPa";

const rel148 = "\mu_{d} = 5.4";
const rel149 = "b x h = 305 x 305mm";
const rel150 = "L = 1473 mm";
const rel151 = "f_{c}' = 43MPa";
const rel152 = "f_{y} = 465 MPa";
const rel153 = "f_{yh} = 505 MPa";
const rel154 = "\vrho_{l}(%) = 2.58";
const rel155 = "\vrho_{t}(%) = 0.61";
const rel156 = "P/(f_{c}' A_{g}) = 0.33, 0.56";
const rel157 = "M/(VH) = 4.8";
const rel158 = "\vtext {CFRP sheets 1.25 mm thick, } \vepsilon_{rup}=0.0228, f_{uf}=563 N/mm, \vtext {one to six layers}";

const rel159 = "\mu_{d} = 6.67, \vtext{steel jacket}";
const rel160 = "\mu_{d} = 5.29, \vtext{CFRP wraps}";
const rel161 = "\mu_{d} = 2.91, \vtext{concrete jacket}";
const rel162 = "\mu_{d} = 6.7, \vtext{AMF concrete jacket}";
const rel163 = "b x h = 300 x 300mm";
const rel164 = "L = 1660 mm";
const rel165 = "f_{c}' = 15.7 - 19.1 MPa";
const rel166 = "f_{y} = 375 MPa";
const rel167 = "f_{yh} = 310 MPa";
const rel168 = "\vrho_{l}(%) = 2.03, 5.28";
const rel169 = "\vrho_{t}(%) = 0.35 \pm 3.48";
const rel170 = "P/(f_{c}' A_{g}) = 0.30";
const rel171 = "M/(VH) = 3.3";
const rel172 = "\vtext {GFRP sheets 0.3 mm thick, } \vepsilon_{rup} = 0.015, f_{uf} = \vtext {3450 MPa, 2 layers. Steel jacketing, 3mm plate thickness. Concrete jacketing, 70mm thickness. AMF concrete jacketing, 50mm thickness}";






const LSExperimental = () => {
  //javascript
  const title = 'Retrofitted Piers';
  const metatitle = 'Retrofitted Piers';
  const description = 'Meta Description for Retrofitted Piers';
  const metadescription = description;

  const scrollToRef = (ref) => window.scrollTo({
    left:0,
    top:ref.current.offsetTop,
    behavior: 'smooth'
  });
  
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);
  const Ref16 = useRef(null);
  const Ref17 = useRef(null);
  const Ref18 = useRef(null);
  const Ref19 = useRef(null);
  const Ref20 = useRef(null);
  const Ref21 = useRef(null);
  const Ref22 = useRef(null);
  const Ref23 = useRef(null);
  const Ref24 = useRef(null);
  const Ref25 = useRef(null);
  const Ref26 = useRef(null);
  const Ref27 = useRef(null);
  const Ref28 = useRef(null);
  
  const executeScroll = (e, ref) => {
    e.preventDefault();
    scrollToRef(ref);
  }

  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
        <li>
            <a className="more" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more actclass" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Rectangular Piers</h1>
          <div className="content-table">
            <h2>Table 1: Rectangular Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> Aboutaha, R. S., Engelhardt, M. D., Jirsa, J. O., Kreger, M. E. (1999)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel1}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of all long. bars, essentially elastic response</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel2}</MathJax.Node><br /><MathJax.Node inline>{rel3}</MathJax.Node><br /><MathJax.Node inline>{rel4}</MathJax.Node><br /><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node><br /><MathJax.Node inline>{rel10}</MathJax.Node><br /><MathJax.Node inline>{rel11}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel12}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended bond failure between long. bars and concrete, deterioration of concrete at the compression zones</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button> Bett, B. J., Klinger, R. E., Jirsa, J. O. (1985)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel13}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Flexural cracks less than 1mm wide</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel14}</MathJax.Node><br /><MathJax.Node inline>{rel15}</MathJax.Node><br /><MathJax.Node inline>{rel16}</MathJax.Node><br /><MathJax.Node inline>{rel17}</MathJax.Node><br /><MathJax.Node inline>{rel18}</MathJax.Node><br /><MathJax.Node inline>{rel19}</MathJax.Node><br /><MathJax.Node inline>{rel20}</MathJax.Node><br /><MathJax.Node inline>{rel21}</MathJax.Node><br /><MathJax.Node inline>{rel22}</MathJax.Node><br /><MathJax.Node inline>{rel23}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel24}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing and spalling of concrete</td>
                </tr>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button> Bousias, S. N, Spathis, L.A., Fardis, M.N. (2006)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel25}</MathJax.Node><br /><MathJax.Node inline>{rel26}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Effective yielding </td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel27}</MathJax.Node><br /><MathJax.Node inline>{rel28}</MathJax.Node><br /><MathJax.Node inline>{rel29}</MathJax.Node><br /><MathJax.Node inline>{rel30}</MathJax.Node><br /><MathJax.Node inline>{rel31}</MathJax.Node><br /><MathJax.Node inline>{rel32}</MathJax.Node><br /><MathJax.Node inline>{rel33}</MathJax.Node><br /><MathJax.Node inline>{rel34}</MathJax.Node><br /><MathJax.Node inline>{rel35}</MathJax.Node><br /><MathJax.Node inline>{rel36}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel37}</MathJax.Node><br /><MathJax.Node inline>{rel38}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Lateral resistance drops below 80% of maximum resistance</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref4)}>[4]</button> Bousias, S. N, Spathis, L.A., Fardis, M.N. (2007)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel39}</MathJax.Node><br /><MathJax.Node inline>{rel40}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Effective yielding </td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel41}</MathJax.Node><br /><MathJax.Node inline>{rel42}</MathJax.Node><br /><MathJax.Node inline>{rel43}</MathJax.Node><br /><MathJax.Node inline>{rel44}</MathJax.Node><br /><MathJax.Node inline>{rel45}</MathJax.Node><br /><MathJax.Node inline>{rel46}</MathJax.Node><br /><MathJax.Node inline>{rel47}</MathJax.Node><br /><MathJax.Node inline>{rel48}</MathJax.Node><br /><MathJax.Node inline>{rel49}</MathJax.Node><br /><MathJax.Node inline>{rel50}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel51}</MathJax.Node><br /><MathJax.Node inline>{rel52}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing, long. bars buckling, extended concrete spalling, FRP fracture</td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref5)}>[5]</button> Endeshaw, M. A., ElGawady, M., Sack, R. L., McLean, D. I. (2008)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel53}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Effective yielding </td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel54}</MathJax.Node><br /><MathJax.Node inline>{rel55}</MathJax.Node><br /><MathJax.Node inline>{rel56}</MathJax.Node><br /><MathJax.Node inline>{rel57}</MathJax.Node><br /><MathJax.Node inline>{rel58}</MathJax.Node><br /><MathJax.Node inline>{rel59}</MathJax.Node><br /><MathJax.Node inline>{rel60}</MathJax.Node><br /><MathJax.Node inline>{rel61}</MathJax.Node><br /><MathJax.Node inline>{rel62}</MathJax.Node><br /><MathJax.Node inline>{rel63}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel64}</MathJax.Node><br /><MathJax.Node inline>{rel65}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete spalling</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel66}</MathJax.Node><br /><MathJax.Node inline>{rel67}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% drop in peak lateral load resistance, buckling of long. bars and rupture</td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref6)}>[6]</button> Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div>Shear enhancement columns<br /><MathJax.Node inline>{rel68}</MathJax.Node><br /><br />Lap splice columns<br /><MathJax.Node inline>{rel69}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% drop of maximum lateral resistance, concrete crushing within the plastic hinge region</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel70}</MathJax.Node><br /><MathJax.Node inline>{rel71}</MathJax.Node><br /><MathJax.Node inline>{rel72}</MathJax.Node><br /><MathJax.Node inline>{rel73}</MathJax.Node><br /><MathJax.Node inline>{rel74}</MathJax.Node><br /><MathJax.Node inline>{rel75}</MathJax.Node><br /><MathJax.Node inline>{rel76}</MathJax.Node><br /><MathJax.Node inline>{rel77}</MathJax.Node><br /><MathJax.Node inline>{rel78}</MathJax.Node><br /><MathJax.Node inline>{rel79}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref7)}>[7]</button> Truong, G. T., Kim, J.-C., Choi, K.-K. (2017)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel80}</MathJax.Node><br /><MathJax.Node inline>{rel81}</MathJax.Node><br /><MathJax.Node inline>{rel82}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Longitudinal bar yielding</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel83}</MathJax.Node><br /><MathJax.Node inline>{rel84}</MathJax.Node><br /><MathJax.Node inline>{rel85}</MathJax.Node><br /><MathJax.Node inline>{rel86}</MathJax.Node><br /><MathJax.Node inline>{rel87}</MathJax.Node><br /><MathJax.Node inline>{rel88}</MathJax.Node><br /><MathJax.Node inline>{rel89}</MathJax.Node><br /><MathJax.Node inline>{rel90}</MathJax.Node><br /><MathJax.Node inline>{rel91}</MathJax.Node><br /><MathJax.Node inline>{rel92}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel93}</MathJax.Node><br /><MathJax.Node inline>{rel94}</MathJax.Node><br /><MathJax.Node inline>{rel95}</MathJax.Node><br /><MathJax.Node inline>{rel96}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>Aboutaha, R. S., Engelhardt, M. D., Jirsa, J. O., Kreger, M. E. (1999). Rehabilitation of Shear Critical Concrete Columns by Use of Rectangular Steel Jackets, ACI Structural Journal, vol. 96, no. 1, pp 68-78, <a href="https://doi.org/10.14359/597">https://doi.org/10.14359/597</a></li>
              <li ref={Ref2}>Bett, B.J., Klingner, R.E., and Jirsa, J.O. (1985). Behavior of Strengthened and Repaired Reinforced Concrete Columns Under Cyclic Deformations, PMFSEL Report No. 85-3, Department of Civil Engineering, University of Texas at Austin, Austin, Texas, 1985.</li>
              <li ref={Ref3}>Bousias, S. N, Spathis, L.A., Fardis, M.N. (2006). Concrete or FRP Jacketing of Columns with Lap Splices for Seismic Rehabilitation, Journal of Advanced Concrete Technology, Vol. 4, No. 3, 2006, pp. 1-14, <a href="https://doi.org/10.1007/1-4020-4571-9_3">https://doi.org/10.1007/1-4020-4571-9_3.</a> </li>
              <li ref={Ref4}>Bousias, S. N, Spathis, L.A., Fardis, M.N. (2007). Seismic Retrofitting of Columns with Lap-Spliced Smooth Bars through FRP or Concrete Jackets, Journal of Earthquake Engineering, 11, 2007, pp. 653–674, <a href="https://doi.org/10.1080/13632460601125714">https://doi.org/10.1080/13632460601125714.</a> </li>
              <li ref={Ref5}>Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003). Experimental Investigation of Seismic Repair and Retrofit of Bridge Columns by Composite Jackets, Journal of REINFORCED PLASTICS AND COMPOSITES, Vol. 22, No. 14, pp 1243-1268, <a href="https://doi.org/10.1177%2F0731684403035573">https://doi.org/10.1177%2F0731684403035573</a></li>
              <li ref={Ref6}>Endeshaw, M. A., ElGawady, M., Sack, R. L., McLean, D. I. (2008). RETROFIT OF RECTANGULAR BRIDGE COLUMNS USING CFRP WRAPPING, Research Report, FHWA contract DTFH61-03-C-00104, Research Task No. 7, Washington State Transportation Center (TRAC), Washington State University, Department of Civil & Environmental Engineering.</li>
              <li ref={Ref7}>Truong, G. T., Kim, J.-C., Choi, K.-K. (2017). Seismic performance of reinforced concrete columns retrofitted by various methods, Engineering Structures, vol. 134, pp 217-235, <a href="https://doi.org/10.1016/j.engstruct.2016.12.046">https://doi.org/10.1016/j.engstruct.2016.12.046.</a></li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 2: Rectangular Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility, μ<sub>d</sub></th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref8)}>[1]</button> Bousias, S. N, Spathis, L.A., Fardis, M.N. (2006)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel97}</MathJax.Node><br /><MathJax.Node inline>{rel98}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Lateral resistance drops below 80% of maximum resistance</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel99}</MathJax.Node><br /><MathJax.Node inline>{rel100}</MathJax.Node><br /><MathJax.Node inline>{rel101}</MathJax.Node><br /><MathJax.Node inline>{rel102}</MathJax.Node><br /><MathJax.Node inline>{rel103}</MathJax.Node><br /><MathJax.Node inline>{rel104}</MathJax.Node><br /><MathJax.Node inline>{rel105}</MathJax.Node><br /><MathJax.Node inline>{rel106}</MathJax.Node><br /><MathJax.Node inline>{rel107}</MathJax.Node><br /><MathJax.Node inline>{rel108}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref9)}>[2]</button> Bousias, S. N, Spathis, L.A., Fardis, M.N. (2007)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel109}</MathJax.Node><br /><MathJax.Node inline>{rel110}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing, long. bars buckling, extended concrete spalling, FRP fracture</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel111}</MathJax.Node><br /><MathJax.Node inline>{rel112}</MathJax.Node><br /><MathJax.Node inline>{rel113}</MathJax.Node><br /><MathJax.Node inline>{rel114}</MathJax.Node><br /><MathJax.Node inline>{rel115}</MathJax.Node><br /><MathJax.Node inline>{rel116}</MathJax.Node><br /><MathJax.Node inline>{rel117}</MathJax.Node><br /><MathJax.Node inline>{rel118}</MathJax.Node><br /><MathJax.Node inline>{rel119}</MathJax.Node><br /><MathJax.Node inline>{rel120}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref10)}>[3]</button> Endeshaw, M. A., ElGawady, M., Sack, R. L., McLean, D. I. (2008)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel121}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Effective yielding</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel122}</MathJax.Node><br /><MathJax.Node inline>{rel123}</MathJax.Node><br /><MathJax.Node inline>{rel123}</MathJax.Node><br /><MathJax.Node inline>{rel124}</MathJax.Node><br /><MathJax.Node inline>{rel125}</MathJax.Node><br /><MathJax.Node inline>{rel126}</MathJax.Node><br /><MathJax.Node inline>{rel127}</MathJax.Node><br /><MathJax.Node inline>{rel128}</MathJax.Node><br /><MathJax.Node inline>{rel129}</MathJax.Node><br /><MathJax.Node inline>{rel130}</MathJax.Node><br /><MathJax.Node inline>{rel131}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel132}</MathJax.Node><br /><MathJax.Node inline>{rel133}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete spalling</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel134}</MathJax.Node><br /><MathJax.Node inline>{rel135}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% drop in peak lateral load resistance, buckling of long. bars and rupture</td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref11)}>[4]</button> Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div>Shear enhancement columns<br /><MathJax.Node inline>{rel136}</MathJax.Node><br />Lap splice columns<br /><MathJax.Node inline>{rel137}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% drop of maximum lateral resistance, concrete crushing within the plastic hinge region, lap splice failure</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel137}</MathJax.Node><br /><MathJax.Node inline>{rel138}</MathJax.Node><br /><MathJax.Node inline>{rel139}</MathJax.Node><br /><MathJax.Node inline>{rel140}</MathJax.Node><br /><MathJax.Node inline>{rel141}</MathJax.Node><br /><MathJax.Node inline>{rel142}</MathJax.Node><br /><MathJax.Node inline>{rel143}</MathJax.Node><br /><MathJax.Node inline>{rel144}</MathJax.Node><br /><MathJax.Node inline>{rel145}</MathJax.Node><br /><MathJax.Node inline>{rel146}</MathJax.Node><br /><MathJax.Node inline>{rel147}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref12)}>[5]</button> Memon, M. S. and Sheikh, S. A. (2005)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel148}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of lateral steel and/or buckling of longitudinal reinforcement, 20% drop in lateral resistance</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel149}</MathJax.Node><br /><MathJax.Node inline>{rel150}</MathJax.Node><br /><MathJax.Node inline>{rel151}</MathJax.Node><br /><MathJax.Node inline>{rel152}</MathJax.Node><br /><MathJax.Node inline>{rel153}</MathJax.Node><br /><MathJax.Node inline>{rel154}</MathJax.Node><br /><MathJax.Node inline>{rel155}</MathJax.Node><br /><MathJax.Node inline>{rel156}</MathJax.Node><br /><MathJax.Node inline>{rel157}</MathJax.Node><br /><MathJax.Node inline>{rel158}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref13)}>[6]</button> Truong, G. T., Kim, J.-C., Choi, K.-K. (2017)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel159}</MathJax.Node><br /><MathJax.Node inline>{rel160}</MathJax.Node><br /><MathJax.Node inline>{rel161}</MathJax.Node><br /><MathJax.Node inline>{rel162}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel163}</MathJax.Node><br /><MathJax.Node inline>{rel164}</MathJax.Node><br /><MathJax.Node inline>{rel165}</MathJax.Node><br /><MathJax.Node inline>{rel166}</MathJax.Node><br /><MathJax.Node inline>{rel167}</MathJax.Node><br /><MathJax.Node inline>{rel168}</MathJax.Node><br /><MathJax.Node inline>{rel169}</MathJax.Node><br /><MathJax.Node inline>{rel170}</MathJax.Node><br /><MathJax.Node inline>{rel171}</MathJax.Node><br /><MathJax.Node inline>{rel172}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref8}>Bousias, S. N, Spathis, L.A., Fardis, M.N. (2006). Concrete or FRP Jacketing of Columns with Lap Splices for Seismic Rehabilitation, Journal of Advanced Concrete Technology, Vol. 4, No. 3, 2006, pp. 1-14, <a href="https://doi.org/10.1007/1-4020-4571-9_3">https://doi.org/10.1007/1-4020-4571-9_3.</a> </li>
              <li ref={Ref9}>Bousias, S. N, Spathis, L.A., Fardis, M.N. (2007). Seismic Retrofitting of Columns with Lap-Spliced Smooth Bars through FRP or Concrete Jackets, Journal of Earthquake Engineering, 11, 2007, pp. 653–674, <a href="https://doi.org/10.1080/13632460601125714">https://doi.org/10.1080/13632460601125714.</a> </li>
              <li ref={Ref10}>Endeshaw, M. A., ElGawady, M., Sack, R. L., McLean, D. I. (2008). RETROFIT OF RECTANGULAR BRIDGE COLUMNS USING CFRP WRAPPING, Research Report, FHWA contract DTFH61-03-C-00104, Research Task No. 7, Washington State Transportation Center (TRAC), Washington State University, Department of Civil & Environmental Engineering.</li>
              <li ref={Ref11}>Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003). Experimental Investigation of Seismic Repair and Retrofit of Bridge Columns by Composite Jackets, Journal of REINFORCED PLASTICS AND COMPOSITES, Vol. 22, No. 14, pp 1243-1268, <a href="https://doi.org/10.1177%2F0731684403035573">https://doi.org/10.1177%2F0731684403035573</a></li>
              <li ref={Ref12}>Memon, M. S. and Sheikh, S. A. (2005). Seismic Resistance of Square Concrete Columns Retrofitted with Glass Fiber-Reinforced Polymer, ACI Structural Journal, Vol. 102, No. 5, pp 774-783. </li>
              <li ref={Ref13}>Truong, G. T., Kim, J.-C., Choi, K.-K. (2017). Seismic performance of reinforced concrete columns retrofitted by various methods, Engineering Structures, vol. 134, pp 217-235, <a href="https://doi.org/10.1016/j.engstruct.2016.12.046">https://doi.org/10.1016/j.engstruct.2016.12.046.</a></li>
            </ol>
          </div>
        </section>
        
      </div>
      
      
    </Layout>
  )
}

export default LSExperimental
